import React from 'react';

import './CardQC.css';
import CardItem from './CardItem';
import { FaAtom } from 'react-icons/fa';
import partnershipLS from './img/partnership-Laplace-Sacados.png';
import graph_quantum from "./img/graph_save_ressource.png"

function Cards() {
  let ifstyle = "border-radius:12px" ;
  return (
    <div id='cards-section'>
    <div className="quantum-pag">

      <div className="quantum-containe">
        
        <iframe style={{ifstyle}}
        src="https://open.spotify.com/embed/episode/5mm9lXDsfzCZghyuDTv9Iw?utm_source=generator" 
        width="100%" height="352" frameBorder="0" 
        allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

        <div className="title2">{"< Quantum Machine Learning | Quantum Scientific Computing >"}</div>

        <h2>Transform Healthcare with Anzaetek’s Quantum Machine Learning &amp; Scientific Computing  Solutions</h2>

        <p>
        {"Anzaetek, in partnership with Seoul National University Bundang Hospital (SNUBH) Quantum Lab, is pioneering the application of quantum and quantum-inspired computing to healthcare, driving innovations in surgical video analysis and medical technologies."} 
        </p>
        <p>
        At Anzaetek, we understand the power of Quantum Machine Learning in pushing the boundaries of industries like healthcare. 
        Our collaboration with SNUBH represents a breakthrough in Applied Quantum Computing.
        </p>
        <p>
        We remain dedicated to advancing quantum technologies and applying them to scientific challenges, 
        collaborating with top institutions to deliver industry-leading results that redefine innovation in fields such as healthcare, finance, and scientific research.
        </p>

        <p>
        If you're ready to take your operations to the next level with cutting-edge quantum scientific computing solutions, now is the time to act.
        </p> 

        <h2>Choose Anzaetek</h2>

        <p>
        Get in touch with us today at <a className="contact-email" href="mailto:info@anzaetek.com">info@anzaetek.com</a> and join the leaders transforming industries through quantum innovation.
        </p>

        <FaAtom className="ico" />
      </div>
    </div>
    </div>
  );
}

export default Cards;
